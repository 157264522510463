<template>
  <div data-vue-component-name="TechnicalRoomCategories">
    <ButtonBack @click="getBack" />

    <h1 class="title">{{ pageTitle }}</h1>
    <p class="description">{{ pageDescription }}</p>
    <hr>

    <ul class="navigation">
      <li
        v-for="(category, index) in categoriesList"
        :key="category.categoryName"
        @click="changeCategory(index)"
      >
        <span :class="{ active: index === categoryIndex }">
          {{ category.categoryName }}
        </span>
      </li>
    </ul>

    <hr>

    <div
      class="category-preview"
      v-touch:swipe.right="() => changeCategory(categoryIndex - 1)"
      v-touch:swipe.left="() => changeCategory(categoryIndex + 1)"
    >
      <img
        class="background"
        draggable="false"
        src="/backgrounds/high-podium.png"
        alt="categories viewer background"
      >

      <transition name="fade" appear>
        <div
          v-if="category"
          :key="category"
        >
          <div class="tooltip">
            <span class="name">
              {{ category.categoryName }}
            </span>
          </div>

          <AppImage
            class="image"
            :webp="category.categoryImageWebp.image"
            :png="category.categoryImage.image"
            :alt="category.categoryImageWebp.label"
          />
        </div>
      </transition>

      <button
        class="arrow left"
        @click="changeCategory(categoryIndex - 1)"
      >
        <IconArrow />
      </button>

      <button
        class="arrow right"
        @click="changeCategory(categoryIndex + 1)"
      >
        <IconArrow />
      </button>
    </div>

    <AppButton
      class="view-more"
      pill
      type="fill"
      background="primary"
      @click="next"
    >
      <span>view more</span>
      <IconArrow />
    </AppButton>

    <AppWrapper
      v-if="apointment"
      class="apointment"
      bg-color="rgba(255, 255, 255, 0.25)"
    >
      <h2 class="title">
        {{ apointment.formTitle }}
      </h2>

      <p class="description">
        {{ apointment.formText }}
      </p>

      <AppButton
        pill
        type="fill"
        background="primary"
        radius="4px"
        @click="openForm"
      >
        <span>Request an Apointment</span>
        <IconArrow />
      </AppButton>
    </AppWrapper>
  </div>
</template>

<script setup>
// dependencies
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// components
import {
  AppButton,
  ButtonBack,
  IconArrow,
  AppImage,
  AppWrapper,
} from '@xsys2/components';

// helpers
import { get, getValidRange, isAllKeysExist } from '@xsys2/functions';

const props = defineProps({
  categoryIndex: {
    type: Number,
    default: null,
  },
  productIndex: {
    type: Number,
    default: null,
  },
});

const emits = defineEmits({
  'update:categoryIndex': value => typeof value === 'number',
  'update:productIndex': value => typeof value === 'number',
  openForm: value => isAllKeysExist(value, [
    'fields',
    'formText',
    'formTitle',
    'subject',
  ]),
  next: value => typeof value === 'undefined',
});

const store = useStore();
const router = useRouter();

const getBack = () => router.go(-1);

// COMPUTEDS
const pageTitle = computed(
  () => store.state.technicalRoom.pageTitle,
);
const pageDescription = computed(
  () => store.state.technicalRoom.pageDescription,
);
const categoriesList = computed(() => get(
  store.state.technicalRoom.productCategoriesGroups,
  '[0].productCategoriesList',
));
const category = computed(() => get(
  categoriesList.value,
  `[${props.categoryIndex}]`,
));
const apointment = computed(
  () => store.state.technicalRoom.contactFormProductRequestAnApointment,
);

// handlers
const changeCategory = index => emits(
  'update:categoryIndex',
  getValidRange(index, categoriesList.value.length - 1, 0),
);
const next = () => emits('next');
const openForm = () => emits('openForm', {
  ...apointment.value,
  subject: 'Technical Room - Request an Apointment',
});
</script>

<style scoped lang="scss">
[data-vue-component-name="TechnicalRoomCategories"] {
  background-color: #000;
  color: #fff;
  overflow: auto;

  & > {
    [data-vue-component-name="ButtonBack"] {
      margin-bottom: 32px;
    }

    .title {
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: 0.1em;
      margin-bottom: 24px;
    }

    .description {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      opacity: 0.75;
      margin-bottom: 32px;
    }

    hr {
      border: none;
      height: 1px;
      background-color: #fff;
      opacity: 0.1;
      margin-bottom: 32px;
    }

    .navigation {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      li {
        margin-bottom: 32px;
        width: max-content;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          cursor: pointer;

          &.active {
            text-decoration: underline;
          }
        }
      }
    }

    .category-preview {
      margin: 0 -32px;
      position: relative;

      img.background {
        width: 100%;
      }

      .tooltip {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        padding: 16px;
        border-radius: 4px;
        opacity: 0.75;
        z-index: 1;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
          width: 12px;
          height: 12px;
          background-color: inherit;
        }

        .name {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #DA291C;
        }
      }

      .image {
        @include absolute-full;
        z-index: 0;
        object-fit: contain;
      }

      button.arrow {
        position: absolute;
        top: 50%;
        padding: 32px;
        box-sizing: border-box;

        [data-vue-component-name="IconArrow"] {
          width: 24px;
          height: 24px;
        }

        &.left {
          left: 0;
          transform: rotate(180deg);
        }

        &.right {
          right: 0;
          margin-top: 3px;
        }
      }
    }

    .view-more {
      margin: 0 auto;
      padding: 16px 64px;
      margin-bottom: 32px;

      span {
        text-transform: uppercase;
        margin-right: 8px;
        font-weight: 600;
      }

      [data-vue-component-name="IconArrow"] {
        height: 20px;
        width: 20px;
      }
    }

    .apointment {
      padding: 32px;
      border-radius: 8px;

      h2.title {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 24px;
      }

      p.description {
        white-space: pre-wrap;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 42px;
      }

      [data-vue-component-name="AppButton"] {
        width: 100%;
        padding: 24px 0;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
