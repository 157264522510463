<template>
  <div data-vue-component-name="TechnicalRoom">
    <transition name="fade" appear>
      <component
        id="dynamic-page"
        :key="pageComponent"
        :is="pageComponent"
        v-model:category-index="categoryIndex"
        v-model:product-index="productIndex"
        @open-form="props => toggleForm(true, props)"
        @next="changeComponent(+1)"
        @prev="changeComponent(-1)"
      />
    </transition>

    <transition name="fade">
      <AppWrapper
        v-if="isFormOpened"
        id="form-wrapper"
        blur="32"
      >
        <ButtonClose @click="toggleForm(false, null)" />

        <AppForm
          :title="formProps.formTitle"
          :description="formProps.formText"
          :inputs="formProps.fields"
          :subject="formProps.subject"
          @on-submit="onSubmit"
        />
      </AppWrapper>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { getValidRange, transformDataToHtmlTable } from '@xsys2/functions';

// components
import TechnicalRoomCategories from '@/views/TechnicalRoomCategories.vue';
import TechnicalRoomProducts from '@/views/TechnicalRoomProducts.vue';
import TechnicalRoomProduct from '@/views/TechnicalRoomProduct.vue';
import { AppWrapper, AppForm, ButtonClose } from '@xsys2/components';

const store = useStore();
store.dispatch('technicalRoom/getData');

// transition between pages
const pages = [
  TechnicalRoomCategories,
  TechnicalRoomProducts,
  TechnicalRoomProduct,
];
const pageIndex = ref(0);
const pageComponent = computed(() => pages[pageIndex.value]);
const changeComponent = (index) => {
  pageIndex.value = getValidRange(
    pageIndex.value + index,
    pages.length - 1,
    0,
  );
};

// categories & products
const categoryIndex = ref(0);
const productIndex = ref(0);

// form
const isFormOpened = ref(false);
const formProps = ref(null);

const toggleForm = (payload = false, props = null) => {
  isFormOpened.value = payload;
  formProps.value = props;
};
const onSubmit = async ({ subject, values, resetForm }) => {
  const result = await store.dispatch(
    'sendForm',
    {
      subject,
      message: transformDataToHtmlTable(values),
    },
  );

  if (result) {
    resetForm();
    toggleForm();
  }
};
</script>

<style scoped lang="scss">
[data-vue-component-name="TechnicalRoom"] {
  width: 100vw;
  height: 100%;

  #dynamic-page {
    width: 100%;
    height: 100%;
    padding: 32px;
  }

  #form-wrapper {
    @include absolute-full;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 32px 16px;

    [data-vue-component-name="ButtonClose"] {
      width: max-content;
      margin-bottom: 24px;
    }

    [data-vue-component-name="AppForm"] {
      background: rgba(255, 255, 255, 0.2);
      padding: 32px;
      overflow-y: auto;
      width: 100%;
    }
  }
}
</style>
