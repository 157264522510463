<template>
  <div data-vue-component-name="TechnicalRoomProducts">
    <ButtonBack @click="getBack" />

    <h1 class="title">{{ category.categoryName }}</h1>

    <div
      class="description"
      v-html="category.categoryDescription"
    />

    <hr>

    <AppButton
      class="button-next"
      pill
      type="fill"
      background="custom"
      background-color="rgba(255, 255, 255, 0.25)"
      @click="next"
    >
      <IconBox />
      <span>3D view</span>
    </AppButton>

    <div class="product-container">
      <transition name="fade" appear>
        <AppImage
          class="product-preview"
          :key="productIndex"
          :webp="product.productImageWebp.url"
          :png="product.productImage.url"
          :alt="product.productName"
        />
      </transition>
    </div>

    <TechnicalRoomProductsSlider
      :active-index="productIndex"
      :items="productsNames"
      @update-active-index="onUpdateActiveIndex"
    />

    <TechnicalRoomBenefits :markup="category.yourBenefits" />

    <AppForm
      :title="contactFormProduct.formTitle"
      :description="contactFormProduct.formText"
      :inputs="contactFormProduct.fields"
      :subject="`Technical Room - ${category.categoryName}`"
      @on-submit="onSubmit"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  ButtonBack,
  AppButton,
  IconBox,
  AppImage,
  TechnicalRoomBenefits,
  AppForm,
} from '@xsys2/components';

// helpers
import { transformDataToHtmlTable } from '@xsys2/functions';

const props = defineProps({
  categoryIndex: {
    type: Number,
    default: null,
  },
  productIndex: {
    type: Number,
    default: null,
  },
});

const emits = defineEmits({
  prev: value => typeof value === 'undefined',
  next: value => typeof value === 'undefined',
  'update:productIndex': index => typeof index === 'number',
});

const store = useStore();

// computeds
const category = computed(
  () => store.state.technicalRoom
    .productCategoriesGroups[0]
    .productCategoriesList[props.categoryIndex],
);
const product = computed(
  () => category.value.products[props.productIndex],
);
const productsNames = category.value.products
  .map(({ productName }) => productName);
const contactFormProduct = computed(
  () => store.state.technicalRoom.contactFormProduct,
);

// handlers
const getBack = () => {
  emits('prev');
  emits('update:productIndex', 0);
};
const next = () => emits('next');

const onUpdateActiveIndex = index => emits('update:productIndex', index);

const onSubmit = async ({ subject, values, resetForm }) => {
  const result = await store.dispatch(
    'sendForm',
    {
      subject,
      message: transformDataToHtmlTable(values),
    },
  );

  if (result) {
    resetForm();
  }
};
</script>

<style scoped lang="scss">
[data-vue-component-name="TechnicalRoomProducts"] {
  background-color: rgb(51, 51, 51);
  color: #fff;
  overflow: auto;

  & > {
    [data-vue-component-name="ButtonBack"] {
      margin-bottom: 32px;
    }

    .title {
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: 0.1em;
      margin-bottom: 24px;
    }

    .description {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      opacity: 0.75;
      margin-bottom: 32px;
    }

    hr {
      border: none;
      height: 1px;
      background-color: #fff;
      opacity: 0.1;
      margin-bottom: 32px;
    }

    .button-next {
      padding: 8px 16px;
      margin: 0 auto 32px auto;

      [data-vue-component-name="IconBox"] {
        margin-right: 16px;
      }

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
      }
    }

    .product-container {
      position: relative;
      aspect-ratio: 1;
      max-height: 311px;
      margin: 0 auto 64px auto;

      .product-preview {
        @include absolute-full;
        object-fit: contain;
      }
    }

    [data-vue-component-name="TechnicalRoomProductsSlider"] {
      width: calc(100% + (32px * 2));
      margin-left: -32px;
      margin-bottom: 32px;
    }

    [data-vue-component-name="TechnicalRoomBenefits"] {}

    [data-vue-component-name="AppForm"] {}
  }
}
</style>
