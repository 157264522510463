<template>
  <div data-vue-component-name="TechnicalRoomProduct">
    <img
      class="background"
      src="/backgrounds/low-podium.png"
      alt="product scene"
      draggable="false"
    >

    <ButtonBack @click="getBack" />
    <h1 class="title">{{ product.productName }}</h1>

    <TechnicalRoomProductView
      :images="images"
      :alt="product.productName"
    />

    <TechnicalRoomCollapsablePanel :panels="infoBlocks" />

    <AppButton
      pill
      type="fill"
      background="primary"
      radius="70px"
      @click="onRequestDemo"
    >
      <span>Request Demo</span>
      <IconArrow />
    </AppButton>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  ButtonBack,
  TechnicalRoomProductView,
  TechnicalRoomCollapsablePanel,
  AppButton,
  IconArrow,
} from '@xsys2/components';
import { isAllKeysExist } from '@xsys2/functions';

const props = defineProps({
  categoryIndex: {
    type: Number,
    default: null,
  },
  productIndex: {
    type: Number,
    default: null,
  },
});

const emits = defineEmits({
  prev: value => typeof value === 'undefined',
  openForm: value => isAllKeysExist(value, [
    'fields',
    'formText',
    'formTitle',
    'subject',
  ]),
});

const store = useStore();
const images = ref([]);

// computeds
const category = computed(
  () => store.state.technicalRoom
    .productCategoriesGroups[0]
    .productCategoriesList[props.categoryIndex],
);
const product = computed(() => category.value.products[props.productIndex]);
const infoBlocks = computed(() => product.value.productSinglPage.infoblocks);
const contactFormProduct = computed(() => store.state.technicalRoom.contactFormProduct);

// functions
const getBack = () => emits('prev');

const loadImages = async (categoryName, productName) => {
  const promises = [];

  for (let i = 0; i < 36; i++) {
    promises.push(
      import(`@xsys2/assets/machines/${categoryName}/${productName}/${i}.png`),
    );
  }

  const response = await Promise.allSettled(promises);

  const modules = response
    .map(module => (module.status === 'rejected' ? null : module.value.default))
    .filter(Boolean);

  return modules.length
    ? modules
    : null;
};

const resortImages = (loadedImages = []) => {
  if (loadedImages?.length === 36) {
    const frontImage = loadedImages.pop();
    const imagesCenterIndex = Math.floor(loadedImages.length / 2);

    const leftHalf = loadedImages
      .slice(0, imagesCenterIndex);

    const rightHalf = loadedImages
      .slice(imagesCenterIndex)
      .concat([frontImage]);

    const [backImage] = rightHalf;

    return rightHalf.concat(leftHalf, [backImage]);
  }

  return loadedImages;
};

loadImages(category.value.categoryName, product.value.productName)
  .then(loadedImages => (images.value = resortImages(loadedImages)));

const onRequestDemo = () => emits('openForm', {
  ...contactFormProduct.value,
  subject: `Technical Room - ${category.value.categoryName} - ${product.value.productName}`,
});
</script>

<style scoped lang="scss">
[data-vue-component-name="TechnicalRoomProduct"] {
  background-color: #000;
  color: #fff;
  position: relative;
  z-index: 0;
  overflow-y: auto;

  & > {
    .background {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      z-index: -1;

      @media (max-width: 768px) {
        top: -30vw;
      }
    }

    [data-vue-component-name="ButtonBack"] {
      margin-bottom: 32px;
    }

    .title {
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: 0.1em;
      margin-bottom: 24px;
    }

    [data-vue-component-name="TechnicalRoomProductView"] {
      &::v-deep([data-vue-component-name="TechnicalRoomProductViewImages"]) {
        aspect-ratio: 1;
      }

      &::v-deep([data-vue-component-name="TechnicalRoomProductViewSlider"]) {}
    }

    [data-vue-component-name="TechnicalRoomCollapsablePanel"] {
      margin-bottom: 24px;
    }

    [data-vue-component-name="AppButton"] {
      margin: 0 auto;
      width: 100%;
      padding: 24px 0;
      box-shadow: 0px 8px 32px rgba(255, 255, 255, 0.25);

      span {
        margin-right: 8px;
        text-transform: uppercase;
      }
    }
  }
}
</style>
